import React from "react"

const Image = ({ image, alt, title, className, style }) => {
  return (
    <img
      src={image}
      alt={alt}
      className={className}
      title={title ? title : alt}
      style={style}
    />
  )
}

export default Image
