import React, { useEffect, useRef } from "react"
import { Helmet } from "react-helmet"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelope,
  faPhone,
  faFax,
  faAt,
} from "@fortawesome/free-solid-svg-icons"

import { gsap } from "gsap"

import Layout from "../components/modules/layout"
import Image from "../components/atoms/image"

import logoGermaniaBrunnen from "../images/germaniabrunnen.png"
// import logoJohanniter from "../images/johanniter.png"
// import logoK3 from "../images/k3.png"
import logoOrion from "../images/orion.png"
import logoRomanis from "../images/romanis.png"
import logoTerra from "../images/terra.png"
import logoTofi from "../images/tofi.png"
// import f1 from "../images/flasche-johanniter-quelle-medium.png"
// import f2 from "../images/flasche-k3-medium.png"
import f3 from "../images/flasche-orion-medium.png"
import f4 from "../images/flasche-romanis-medium.png"
import f5 from "../images/flasche-terra-medium.png"
import f6 from "../images/flasche-terra-naturell.png"
import f7 from "../images/flasche-tofi-medium.png"

const useReveal = () => {
  const revealRefs = useRef([])

  const addToRevealRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el)
    }
  }

  useEffect(() => {
    gsap.from(revealRefs.current, {
      opacity: 0,
      scale: 0.9,
      y: 15,
      duration: 1,
      stagger: {
        from: "random",
        amount: 0.8,
      },
      ease: "Power4.easeOut",
    })
  }, [])

  return { addToRevealRefs }
}

const useFlRotation = () => {
  const flRefs = useRef([])

  const addToFlRefs = (el) => {
    if (el && !flRefs.current.includes(el)) {
      flRefs.current.push(el)
    }
  }

  useEffect(() => {
    const tl = gsap.timeline({
      repeat: -1,
      defaults: { duration: 2 },
    })
    flRefs.current.forEach((fl) => {
      tl.fromTo(
        fl,
        {
          x: 100,
          scale: 0.8,
          autoAlpha: 0,
        },
        {
          x: 0,
          scale: 1,
          autoAlpha: 1,
          ease: "circ.out",
        }
      )
      tl.to(fl, {
        autoAlpha: 0,
        scale: 0.8,
        x: -100,
        ease: "circ.in",
      })
    })
  }, [])

  return { addToFlRefs }
}

const IndexPage = () => {
  const { addToRevealRefs } = useReveal()
  const { addToFlRefs } = useFlRotation()

  return (
    <Layout>
      <>
        <Helmet>
          <style>
            {
              "body {background: rgb(2,0,36);background: linear-gradient(12deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);}"
            }
          </style>
        </Helmet>
        <div className="grid justify-center w-full h-full grid-flow-col gap-4 grid-cols-5-sm lg:grid-cols-5-lg grid-rows-5-sm lg:grid-rows-4-lg">
          <div
            className="col-start-3 row-start-1 text-center card"
            ref={addToRevealRefs}
          >
            <span className="inline-block h-full align-middle"></span>
            <Image
              image={logoRomanis}
              alt="ROMANIS Logo"
              className="inline-block w-32 h-auto align-middle"
            />
          </div>
          {/* <div
            className="col-start-5 row-start-3 text-center card"
            ref={addToRevealRefs}
          >
            <span className="inline-block h-full align-middle"></span>
            <Image
              image={logoJohanniter}
              alt="Johanniter Logo"
              className="inline-block w-32 h-auto align-middle"
            />
          </div> */}
          <div
            className="col-start-1 row-start-1 text-center card"
            ref={addToRevealRefs}
          >
            <span className="inline-block h-full align-middle"></span>
            <Image
              image={logoGermaniaBrunnen}
              alt="Germania Brunnen Logo"
              className="inline-block w-32 h-auto align-middle"
            />
          </div>
          <div className="col-start-5 text-center card" ref={addToRevealRefs}>
            <span className="inline-block h-full align-middle"></span>
            <Image
              image={logoOrion}
              alt="Orion Logo"
              className="inline-block w-32 h-auto align-middle"
            />
          </div>
          <div
            className="col-start-1 row-start-3 text-center card"
            ref={addToRevealRefs}
          >
            <span className="inline-block h-full align-middle"></span>
            <Image
              image={logoTerra}
              alt="Terra Logo"
              className="inline-block w-32 h-auto align-middle"
            />
          </div>
          <div
            className="col-start-4 row-start-4 text-center card"
            ref={addToRevealRefs}
          >
            <span className="inline-block h-full align-middle"></span>
            <Image
              image={logoTofi}
              alt="Tofi Logo"
              className="inline-block w-32 h-auto align-middle"
            />
          </div>

          <div
            className="col-span-2 col-start-2 row-span-2 row-start-2 overflow-hidden text-center lg:col-span-1 card"
            ref={addToRevealRefs}
          >
            <span className="inline-block h-full align-middle"></span>
            <div className="inline-block w-16 h-32 align-middle lg:h-64">
              {/* <div className="fixed fl" ref={addToFlRefs}>
                <Image image={f1} className="w-auto h-32 lg:h-64" />
              </div> */}
              {/* <div className="fixed fl" ref={addToFlRefs}>
                <Image image={f2} className="w-auto h-32 lg:h-64" />
              </div> */}
              <div className="fixed fl" ref={addToFlRefs}>
                <Image image={f3} className="w-auto h-32 lg:h-64" />
              </div>
              <div className="fixed fl" ref={addToFlRefs}>
                <Image image={f4} className="w-auto h-32 lg:h-64" />
              </div>
              <div className="fixed fl" ref={addToFlRefs}>
                <Image image={f5} className="w-auto h-32 lg:h-64" />
              </div>
              <div className="fixed fl" ref={addToFlRefs}>
                <Image image={f6} className="w-auto h-32 lg:h-64" />
              </div>
              <div className="fixed fl" ref={addToFlRefs}>
                <Image image={f7} className="w-auto h-32 lg:h-64" />
              </div>
            </div>
          </div>

          <div
            className="col-span-5 row-span-3 row-start-5 lg:row-span-1 lg:col-span-2 lg:row-start-3 lg:col-start-3 card"
            ref={addToRevealRefs}
          >
            <div className="p-8 prose-sm lg:p-4">
              <h2>Wilhelm Reuschling GmbH</h2>
              <p>
                <FontAwesomeIcon icon={faEnvelope} size="1x" className="mr-2" />
                Postfach 1160, 61101 Bad Vilbel
                <br />
                <span title="Telefon">
                  <FontAwesomeIcon icon={faPhone} size="1x" className="mr-2" />
                  06101/985713-0
                </span>
                <br />
                <FontAwesomeIcon icon={faFax} size="1x" className="mr-2" />
                06101/403-45572
                <br />
                <FontAwesomeIcon icon={faAt} size="1x" className="mr-2" />
                info@wilhelm-reuschling.de
              </p>
            </div>
          </div>
          <div
            className="col-span-5 col-start-1 text-center lg:col-span-1 row-start-8 lg:col-start-2 lg:row-start-4 card"
            ref={addToRevealRefs}
          >
            <span className="inline-block h-full align-middle"></span>
            <div className="inline-block align-middle">
              <p>
                <a className="no-underline hover:underline" href="/impressum">
                  Impressum
                </a>
              </p>
              <p>
                <a className="no-underline hover:underline" href="/datenschutz">
                  Datenschutz
                </a>
              </p>
            </div>
          </div>
        </div>
      </>
    </Layout>
  )
}

export default IndexPage
